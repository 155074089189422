/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'

import {RootState} from '../../../../app/redux/rootReducer'
import {getModal} from '../../../../app/redux/modal/ModalActions'
import {FaChevronDown} from 'react-icons/fa'
import {GoUnverified, GoVerified} from 'react-icons/go'
import {GiModal, GiModalBody, GiModalHeader} from '../../../../app/components/system-ui'
import {UncontrolledTooltip} from 'reactstrap'
import Country from '../../../../app/assets/json/country.json'
// import { toAbsoluteUrl } from '../../../helpers/AssetHelpers'

const Companies: any = () => {
  const profile = useSelector((state: RootState) => state.auth.profile, shallowEqual)
  const {corporate_detail} = useSelector((state: RootState) => state.auth.company)
  const strapiData = useSelector((state: RootState) => state.strapi.data)

  const {profile_accesses} = profile
  const dispatch = useDispatch()

  const [showModalVerify, setShowModalVerify] = useState(false)
  const toggleModalVerify: any = () => setShowModalVerify(!showModalVerify)

  const handleModal = () => {
    if (profile_accesses && profile_accesses.length > 1) {
      return dispatch(getModal({modal: 'MODAL_COMPANY', open: true}))
    }
  }

  // const modalCompanyRef: any = useRef()

  return (
    <div className='d-flex align-items-center' style={{width: '-webkit-fill-available'}}>
      <div className='mobile-input d-flex align-items-top'>
        <span className='company-name' onClick={handleModal}>
          {corporate_detail && corporate_detail.logo ? (
            <img
              className='logo'
              src={corporate_detail.logo}
              alt={corporate_detail.name}
              // style={{height: '24px', marginRight: '10px', alignSelf: 'center'}}
              style={{alignSelf: 'center', maxHeight: '60px'}}
            />
          ) : (
            <div className='logo'>
              {corporate_detail.name
                .split(' ')
                .map((word: string) => word.charAt(0))
                .join('')}
            </div>
          )}

          {corporate_detail.country && (
            <div className='country-flag' style={{fontSize: '12px'}}>
              {/* Country : */}
              <img
                src={require('../../../../../public/media/flags/' +
                  Country.find((obj) => obj.alpha2 == corporate_detail.country)
                    ?.name.replaceAll(' ', '-')
                    .toLowerCase() +
                  '.svg')}
                width='16px'
                // style={{ borderRadius: '3px', marginRight: '10px' }}
                alt={Country.find((obj) => obj.alpha2 == corporate_detail.country)?.name}
              />
              {/* <span style={{fontSize: '20px'}}>{countrySelected?.emoji}</span>{' '} */}
              {/* {Country.find((obj) => obj.alpha2 == corporate_detail.country)?.name} */}
            </div>
          )}
          <div className='company-information'>
            <div className='name'>
              {corporate_detail && corporate_detail.name}
              <>
                {corporate_detail && corporate_detail.is_verified == 1 ? (
                  <div className='verified-status'>
                    <span
                      className='text-primary'
                      // style={{ marginLeft: '8px', marginRight: '8px', fontSize: '150%', marginTop: '6px' }}
                      id='btnVerified'
                    >
                      <GoVerified />
                    </span>
                    <UncontrolledTooltip target={'btnVerified'}>Verified</UncontrolledTooltip>
                  </div>
                ) : (
                  <div className='verified-status'>
                    <span
                      // style={{
                      //   marginLeft: '8px',
                      //   marginRight: '8px',
                      //   fontSize: '150%',
                      //   cursor: 'pointer',
                      //   marginTop: '6px',
                      // }}
                      onClick={toggleModalVerify}
                      id='btnUnverified'
                    >
                      <GoUnverified />
                    </span>
                    <UncontrolledTooltip target={'btnUnverified'}>Unverified</UncontrolledTooltip>
                  </div>
                )}
              </>
            </div>
            <div style={{fontSize: '10pt', fontWeight: 'normal'}}>
              {corporate_detail && corporate_detail.department ? corporate_detail.department : '_'}
            </div>
          </div>
          <div className='caret-indicator'>
            {profile_accesses && profile_accesses.length > 1 && <FaChevronDown className='icon' />}
          </div>
        </span>
      </div>
      <GiModal isOpen={showModalVerify} toggle={toggleModalVerify}>
        <GiModalHeader toggle={toggleModalVerify}>
          {strapiData.modal_unverified_title}
        </GiModalHeader>
        <GiModalBody>
          <div
            dangerouslySetInnerHTML={{
              __html: strapiData.modal_unverified_content
                ? strapiData.modal_unverified_content
                : '',
            }}
          ></div>
        </GiModalBody>
      </GiModal>
    </div>
  )
}

export {Companies}
